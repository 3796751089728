// App.js
import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

const loading = (
  <div className="loader">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Home = React.lazy(() => import('./views/pages/Home'));
const Login = React.lazy(() => import('./views/pages/Login'));
const Skill = React.lazy(() => import('./views/pages/Skill'));
const Program = React.lazy(() => import('./views/pages/Program'))
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  // Function to simulate user login
  const handleLogin = () => {
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
  };

  // Function to simulate user logout
  // const handleLogout = () => {
  //   localStorage.setItem('isLoggedIn', 'false');
  //   setIsLoggedIn(false);
  // };

  // Check local storage for login status on initial load

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Home />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          <Route
            path="/skill/:skillname"
            element={
              isLoggedIn ? (
                <Skill />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/program/:programname"
            element={
              isLoggedIn ? (
                <Program />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
